import { createGlobalStyle, css } from "styled-components"
import theme from "styled-theming"
import { colors, device, zIndex } from "./theme"

const themedBodyStyles = theme("mode", {
  light: css`
    background: ${colors.siteBackground.light};
    color: ${colors.black};
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    table,
    div,
    span {
      color: ${colors.black};
    }
  `,
  dark: css`
    background: ${colors.siteBackground.dark};
    color: ${colors.offWhite};
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ul,
    table,
    div,
    span {
      color: ${colors.offWhite};
    }
  `,
})

export const GlobalStyle = createGlobalStyle`
  html {
    --bs: 0 12px 24px 0 rgba(0, 0, 0, 0.09);
    --default-font-size: 1.8rem;
    --default-heading-font-weight: 700;
    --default-font-weight: normal;
    --font-weight-light: 300;
    --font-weight-lighter: 100;
    --font-stack-title: "Roboto", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Helvetica", "Arial", "sans-serif";
    --font-stack-heading: "Merriweather", "Lucida Bright", "Lucidabright","Lucida Serif", "Lucida", "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", Georgia, serif;
    --font-stack-body: 'Cabin','Helvetica Neue','Arial',-apple-system,'BlinkMacSystemFont','Segoe UI',sans-serif;

    --line-height: 1.5;
    --max-width: 168rem;
    font-size: 62.5%;
    scroll-behavior: smooth;
  }

  body {
    ${themedBodyStyles};
    font: var(--default-font-weight) var(--default-font-size) var(--font-stack-body);
    z-index:${zIndex.initial};
    transition: background-color 0.3s ease;
    color: var(--default-text-color);
  }

  img {
    font-family: var(--font-stack-body);
    text-align: center;
    line-height: 3;
  }
  img:before {
  content: "We're sorry, the image below is broken :(";
  display: block;
  margin-bottom: 10px;
}

img:after {
  content: "(url: " attr(src) ")";
  display: block;
  font-size: 12px;
}

  h1 {
    font-weight: 400;
    font-family: var(--font-stack-title);
  }

  h2, h3, h4, h5, h6 {
    font-weight: var(--default-heading-font-weight);
    font-family: var(--font-stack-heading);
  }

  h2 {

    font-size: calc(var(--default-font-size) *1.75);
  }
  h3 {
    font-size: calc(var(--default-font-size) *1.5);
  }

  h5 {
    font-size: calc(var(--default-font-size)* 1.125);
  }

  h6 {
    font-size: calc(var(--default-font-size));
  }
  p, ul, table, div, span {
    font-size: var(--default-font-size);
    line-height: var(--line-height);
  }

  a {
    color: ${colors.primary.default};
    text-decoration-color: ${colors.primary.light};
  }

  ::selection {
    background: ${colors.mainBrand};
    color: ${colors.white};
  }

  // Custom Scrollbar styles

  body::-webkit-scrollbar {
    width:10px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: ${colors.mainBrand} ${colors.secondary.default};



  }
  body::-webkit-scrollbar-track {
    background: ${colors.secondary.default};
  }
  body.dark-mode::-webkit-scrollbar-track {
    background: ${colors.grayscale.default};
  }
  body::-webkit-scrollbar-thumb {
    background-color: ${colors.accentLight.default} ;
    border-radius: 6px;
    border: 2px solid ${colors.secondary.default};
  }
  body.dark-mode::-webkit-scrollbar-thumb {
    background-color: ${colors.mainBrand};
    border-radius: 6px;
    border: 2px solid ${colors.grayscale.default};
  }

  main {
    @media ${device.md} {
      margin-top: 20rem;
    }
    @media ${device.xl} {
      margin-top: 15rem;
    }
  }

  .wp-block-image {
    width: 100%;
    margin: 0;
    > img {
      max-width:100%;
    }
  }
  .wp-block-columns {
    display: grid;
    grid-gap: 2rem;

    em {
      display: inline-block;
      text-align: center;
      color: ${colors.danger.default};
      font-weight: 500;
    }
    &.has-2-columns {


      @media ${device.md} {
        grid-template-columns: repeat(2, 1fr);
        justify-items: center;
        align-items:center;
      }
    }
  }

  .sr-only {
 border:0;
   clip: rect(1px, 1px, 1px, 1px);
   clip-path: inset(50%);
   height: 1px;
   margin: -1px;
   width: 1px;
   overflow: hidden;
   position: absolute !important;
   word-wrap:normal !important;
  }
`

export default GlobalStyle
