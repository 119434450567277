import React from "react"
import Layout from "../components/layout"

const Page404 =  () => (
  <Layout>
    <div>
      <h1>Oops, that's a 404</h1>
    </div>
  </Layout>
)

export default Page404
