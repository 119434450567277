import "normalize.css"
import React from "react"
import "../assets/style.css"
import { GlobalStyle } from "../styles/global.css"

const Layout = ({ children }) => {
  return (
    <>
      <GlobalStyle />
      <main>
        <div>{children}</div>
      </main>
    </>
  )
}

export default Layout
